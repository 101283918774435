<template>
	<el-card class="monitoring-page-wrap page-container">
		<el-form :inline="true" slot="header" size="small">
			<el-form-item label="项目编号">
				<GlobalSearchBar/>
			</el-form-item>
			<el-form-item>
				<el-input v-model="queryString.str" placeholder="搜索站点名称或编号"></el-input>
			</el-form-item>
		</el-form>
		<div class="container">
			<div class="side-bar">
				<BlockMode ref="BlockMode" @cut="videoBarAction"/>
				<OnlineList ref="OnlineList" :site-list="siteList" :box-num="offset" @emitAction="listAction"/>
			</div>
			<VideoSquare ref="VideoSquare" :box-num="offset" :list="bufferVideoList" :video-fullscreen="videoFullscreen" @emitAction="videoSquareAction"/>
		</div>
	</el-card>
</template>

<script>
import BlockMode from './components/BlockMode.vue';
import OnlineList from './components/OnLineList.vue';
import VideoSquare from './components/VideoSquare.vue';

import {getSiteDetail, getSiteGroup} from '@/api/site.js';
import statusInclude from '@/utils/statusCode';

export default {
	name: 'Videos',
	components: {
		BlockMode,
		OnlineList,
		VideoSquare,
	},
	data() {
		return {
			offset: 4,
			bufferVideoList: [],
			siteList: [],
			siteListCopy: [], // 搜索时用来检索数据
			project: 0,
			searchVal: '',
			queryString: {
				str: '',
			},
			expand: {
				uavState: 0,
				osdkState: 0,
				subState: 0,
				droneUpdate: 0,
				hiveState: 0,
				weather: 0,
				windSpeed: 0,
				isRaining: 0,
				hiveUpdate: 0,
			},
			cacheSiteDetail: {},
			videoFullscreen: false,
			scrollNum: 4, // 全部滚动模式下视频播放的个数
			videoScrollIndex: 0,
		};
	},
	computed: {},
	watch: {
		siteList(val, old) {
			if (!old.length) {
				// this.unlockSocket();
				this.initAddVideo();
			}
		},
		offset(val) {
			// console.log(val, old);
			if (val === 1) {
				this.allListPlay();
			} else {
				this.cancelAllListPlay();
				this.initAddVideo();
				if (val > this.bufferVideoList.length) {
					this.addBox();
				} else if (val < this.bufferVideoList.length) {
					this.removeBox();
				}
			}
		},
		videoScrollIndex() {
			this.carouselVideos();
		},
	},
	created() {
		this.loadData();
		this.initVideoBox();
	},
	mounted() {
		this.fullEvent();
	},
	beforeDestroy() {
		this.bufferVideoList = [];
		this.removeFullEvent();
	},
	methods: {
		/**
		 * 站点列表
		 */
		loadData() {
			getSiteGroup().then((siteData) => {
				const {
					code = 0,
					data: {list = []},
					reason = '',
				} = siteData;
				if (!statusInclude(code)) return this.$message({type: 'warning', message: reason});
				this.siteList = list.map((x) => {
					const active = false;
					const viewValue = 'first';
					return Object.assign(x, this.expand, {active, viewValue});
				});
				this.siteListCopy = JSON.parse(JSON.stringify(this.siteList));
			});
		},
		searchFun() {
			const searchList = this.siteListCopy.filter((val) => this.searchLimit(val));
			this.siteList = searchList;
		},
		searchLimit(data) {
			if (!data) {
				return;
			}
			const {siteName = '', siteID = ''} = data;
			const text = this.searchVal;
			if (siteName.includes(text) || siteID.includes(text)) {
				// eslint-disable-next-line consistent-return
				return true;
			}
			// eslint-disable-next-line consistent-return
			return false;
		},
		/**
		 * 基础数据事件
		 */
		initVideoBox() {
			for (let i = 0; i < this.offset; i += 1) {
				this.bufferVideoList.push({});
			}
		},
		/**
		 * 宫格切换事件
		 */
		// 全部滚动模式 offset = 1
		async allListPlay() {
			this.bufferVideoList = [];
			this.bufferVideoList = JSON.parse(JSON.stringify(this.siteList));
			// 总长度是否能被4整除，不能则在最后一屏时，只取消播放两个
			const remainder = this.bufferVideoList.length % 4;
			for (let i = 0; i < remainder; i += 1) {
				this.bufferVideoList.push({});
			}
			this.siteList.forEach((x) => {
				this.listAddActive(x);
			});
			this.carouselVideos();
		},
		cancelAllListPlay() {
			this.siteList.forEach((x) => {
				this.listRemoveActive(x);
				this.squareRemoveVideo(x);
			});
		},
		// 轮播视频
		async carouselVideos() {
			this.$refs.VideoSquare.removeAllVideo();
			for (let i = 0; i < this.scrollNum; i += 1) {
				const index = i + this.scrollNum * this.videoScrollIndex;
				const item = this.siteList[index];
				// console.log('index', index);
				if (item) {
					// eslint-disable-next-line no-await-in-loop
					await this.squareAddVideo(item);
				}
			}
		},
		// 宫格模式 offset = 4, 6, 9
		addBox() {
			const time = this.offset - this.bufferVideoList.length;
			for (let i = 0; i < time; i += 1) {
				this.bufferVideoList.push({});
			}
		},
		removeBox() {
			const num = this.bufferVideoList.length - this.offset;
			for (let i = 0; i < num; i += 1) {
				const delVideo = this.bufferVideoList.pop();
				if (delVideo.siteID) {
					this.listRemoveActive(delVideo);
				}
			}
		},
		videoBarAction(data) {
			this.offset = data.data.num || 4;
		},
		/**
		 * 站点列表事件
		 */
		listAddActive(item) {
			Object.assign(item, {
				active: true,
			});
			this.copyListUpdata(item, true);
		},
		listRemoveActive(item) {
			Object.assign(item, {
				active: false,
			});
			this.copyListUpdata(item, false);
		},
		async listAction(data) {
			const index = this.siteList.findIndex((x) => x.siteID === data.data.siteID);
			let siteItem = {};
			if (index > -1) {
				siteItem = this.siteList[index];
			}
			if (data && data.action === 'addVideo') {
				this.listAddActive(siteItem);
				this.squareAddVideo(data.data);
			}
			if (data && data.action === 'removeVideo') {
				this.listRemoveActive(siteItem);
				this.squareRemoveVideo(data.data);
			}
			if (data && data.action === 'updateDuration') {
				this.$refs.VideoSquare.updateTime(data.data);
			}
		},
		copyListUpdata(item, active) {
			const index = this.siteListCopy.findIndex((x) => x.siteID === item.siteID);
			if (index > -1) {
				this.siteListCopy[index].active = active;
			}
		},
		/**
		 * 视频播放区事件
		 */
		async squareAddVideo(item) {
			await this.setVideoInfo(item);
			this.$refs.VideoSquare.playVideo(item);
		},
		squareRemoveVideo(item) {
			const videoIndex = this.bufferVideoList.findIndex((x) => x.siteID === item.siteID);
			if (videoIndex > -1) {
				this.$set(this.bufferVideoList, videoIndex, {});
				this.$refs.VideoSquare.removePlay(item.siteID);
			}
		},
		videoSquareAction(data) {
			if (data && data.action === 'delVideo') {
				this.$refs.OnlineList.toggleActive(data.data);
			}
			if (data && data.action === 'videoFullScreen') {
				const dom = document.getElementById(data.data.videoId);
				if (!this.videoFullscreen) {
					this.fullScreen(dom);
				} else {
					this.exitFullscreen();
				}
			}
			if (data && data.action === 'setCurrScrollIndex') {
				this.videoScrollIndex = data.data.currScrollIndex;
			}
		},
		async setVideoInfo(data) {
			const urls = await this.getSiteDetailFun(data.siteID);
			// console.log('setVideoInfo data', data);
			// console.log('setVideoInfo bufferVideoList', this.bufferVideoList);
			// eslint-disable-next-line
			console.log(this.bufferVideoList);
			this.bufferVideoList.some((val, index) => {
				if (!val.siteID) {
					this.$set(this.bufferVideoList, index, Object.assign(data, urls));
					return true;
				}
				if (val.siteID === data.siteID) {
					this.$set(this.bufferVideoList, index, Object.assign(data, urls));
					// this.bufferVideoList[index] = Object.assign(data, urls);
					// this.bufferVideoList[index].dTime = parseFloat(Number(new Date().getTime() / 1000).toFixed(0)) + 1;
					return true;
				}
			});
			// this.bufferVideoList[index] = Object.assign(data, urls);
			// this.addCommonVideo(data);
		},
		/**
		 * 事件
		 */
		async initAddVideo() {
			if (this.offset === 1) {
				return;
			}
			const len = this.offset > this.siteList.length ? this.siteList.length : this.offset;
			for (let i = 0; i < len; i += 1) {
				const item = this.siteList[i];
				if (item) {
					this.listAddActive(item);
					// eslint-disable-next-line no-await-in-loop
					await this.squareAddVideo(item);
				}
			}
		},
		async getSiteListData() {
			const {code, data, reason} = await getSiteGroup();
			if (statusInclude(code)) {
				const {list} = data;
				return list;
			}
			return new Error(`请求站点信息失败${reason}`);
		},
		/**
		 * 获取站点详情
		 */
		async getSiteDetailFun(siteID) {
			let site = {};
			if (this.cacheSiteDetail[siteID]) {
				site = this.cacheSiteDetail[siteID];
			} else {
				const {code, data} = await getSiteDetail(siteID);
				// console.log(code, data);
				if (!statusInclude(code)) {
					this.$message.error('请求数据失败');
					return {first: '', second: '', internal: ''};
				}
				site = data;
				this.cacheSiteDetail[siteID] = data;
			}
			const {UAVInfo = {}, siteFLVURL, hiveInfo = {}} = site;
			// const site = data;
			// if (site.siteMode !== SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox) {
			//   this.$refs.controlsBar.hideHiveLabel();
			//   this.$refs.controlsBar.hideScheduleLabel();
			// }
			let UAVFLVURL = '';
			let hiveFLVURL = '';
			if (UAVInfo && UAVInfo.UAVFLVURL) {
				UAVFLVURL = UAVInfo.UAVFLVURL;
			}
			if (hiveInfo && hiveInfo.hiveFLVURL) {
				hiveFLVURL = hiveInfo.hiveFLVURL;
			}
			return {
				// liveAddr: UAVFLVURL, // UAVFLVURL,
				first: UAVFLVURL,
				second: siteFLVURL,
				internal: hiveFLVURL,
			};
			// this.ANGLESRC.FIRST = UAVFLVURL;
			// // 南京测试视频流，目标追踪、指点变焦
			// //  this.ANGLESRC.FIRST = 'https://play4.skysys.cn/xl_uav/ch96.flv';
			// this.ANGLESRC.SECOND = siteFLVURL;
			// this.ANGLESRC.INTERNAL = hiveFLVURL;
			
			// siteMode 站点模式 ，站点支持的飞行模式 siteSFMode
			// siteMode: 1-固定机库+射频箱 2-射频箱 3-单兵中枢 4-移动机库 5-无人机遥控器 6-天枢
			// 只有1,4的情况： 机库检查，风速
			
			// this.socketInstance = socket.initSocket(); // 初始化socket
			// this.onInformationBySocket(site.siteID); // 开启A站socket连接
		},
		/**
		 * socket事件
		 */
		unlockSocket() {
			this.siteList.forEach((site) => {
				this.socketInstance.on(`${site.siteID} state`, (data) => {
					// 开启主题监听
					const {type} = data;
					if (type === 1) {
						Object.assign(site, {
							uavState: data.isUAVOnline ? 1 : 0,
							osdkState: data.isSCOnline,
							subState: data.subState,
							droneUpdate: new Date().getTime() + 5000,
						});
						// 无人机上线，给站点添加位置
						// if (site.uavState) {
						//   site.siteAddress = '无人机位置';
						//   site.siteLocation = [data.longitudeWGS, data.latitudeWGS];
						// }
					} else {
						const a = data.windSpeed > 8 ? 401 : 200;
						const b = data.isRaining ? 121 : 313;
						Object.assign(site, {
							hiveState: 1,
							weather: a + b,
							windSpeed: data.windSpeed,
							isRaining: b,
							hiveUpdate: new Date().getTime() + 5000,
						});
					}
				});
				this.heartBeating(site);
			});
		},
		heartBeating(site) {
			this.heartBeatingTimer = setInterval(() => {
				const time = new Date().getTime();
				const {droneUpdate, hiveUpdate} = site;
				if (!droneUpdate && !hiveUpdate) {
					Object.assign(site, {
						uavState: 0,
						osdkState: 0,
						subState: 0,
						hiveState: 0,
						weather: 0,
					});
				} else if (droneUpdate && droneUpdate < time) {
					Object.assign(site, {
						uavState: 0,
						osdkState: 0,
						subState: 0,
					});
				} else if (hiveUpdate && hiveUpdate < time) {
					Object.assign(site, {
						hiveState: 0,
						weather: 0,
					});
				}
			}, 30000);
		},
		/**
		 * 全屏事件
		 */
		fullEvent() {
			this.allListener('fullscreenchange');
			this.allListener('webkitfullscreenchange');
			this.allListener('mozfullscreenchange');
			this.allListener('msfullscreenchange');
		},
		removeFullEvent() {
			window.removeEventListener('fullscreenchange', () => {
			});
			window.removeEventListener('webkitfullscreenchange', () => {
			});
			window.removeEventListener('mozfullscreenchange', () => {
			});
			window.removeEventListener('msfullscreenchange', () => {
			});
		},
		allListener(ev) {
			window.addEventListener(ev, () => {
				let isFull = document.fullscreenElement;
				const d = document;
				if (ev === 'webkitfullscreenchange') {
					isFull = d.webkitFullscreenElement;
				} else if (ev === 'mozfullscreenchange') {
					isFull = d.mozFullscreenElement;
				} else if (ev === 'msfullscreenchange') {
					isFull = d.msFullscreenElement;
				}
				if (isFull) {
					this.videoFullscreen = true;
				} else {
					this.videoFullscreen = false;
				}
			});
		},
		fullScreen(dom) {
			if (dom.requestFullscreen) {
				dom.requestFullscreen();
			} else if (dom.msRequestFullscreen) {
				dom.msRequestFullscreen();
			} else if (dom.mozRequestFullScreen) {
				dom.mozRequestFullScreen();
			} else if (dom.webkitRequestFullscreen) {
				dom.webkitRequestFullscreen();
			}
		},
		exitFullscreen() {
			const d = document;
			if (d.exitFullscreen) {
				d.exitFullscreen();
			} else if (d.msExitFullscreen) {
				d.msExitFullscreen();
			} else if (d.mozCancelFullScreen) {
				d.mozCancelFullScreen();
			} else if (d.webkitExitFullscreen) {
				d.webkitExitFullscreen();
			}
		},
		/**
		 * 事件
		 */
	},
};
</script>

<style lang="scss" scoped>
.monitoring-page-wrap {
	/deep/ .el-card__body {
		padding: 0;
		
		.container {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: row;
			
			.side-bar {
				width: 300px;
				display: flex;
				flex-direction: row;
				background-color: var(--main-el-card-header-backgroun);
			}
		}
	}
}
</style>
