<template>
  <div :class="['videos-box', `mode-${boxNum}`]">
    <div v-if="boxNum === 1" id="scrollBox" class="scroll-box">
      <Video v-for="(item, index) in videoList" :key="index" :index="index" :data="item" :is-empty-push="boxNum === 1" :draggable="false" :video-fullscreen="videoFullscreen" @emitAction="VideoEvent" />
    </div>
    <Video v-for="(item, index) in videoList" v-else :key="index" :index="index" :data="item" :draggable="true" :video-fullscreen="videoFullscreen" @emitAction="VideoEvent" />
  </div>
</template>

<script>
import FLV from 'flv.js';
import Video from './VideoPlayer.vue';

let oldData = null;
let newData = null;
const flvPlayers = {};
const timer = {};
let scrollDom = null;

export default {
  // eslint-disable-next-line
  name: 'VideoSquare',
  components: {
    Video,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    boxNum: {
      type: Number,
      default: 4,
    },
    videoFullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      videoList: [],
      currScrollIndex: 0,
      cacheScrollY: 0,
      isScrollUp: false,
    };
  },
  computed: {},
  watch: {
    list: {
      handler(val) {
        // console.log('watch video list', val);
        this.videoList = val;
      },
      immediate: true,
      deep: true,
    },
    boxNum(val) {
      if (val === 1) {
        this.$nextTick(() => {
          scrollDom = document.getElementById('scrollBox');
          if (scrollDom) {
            scrollDom.addEventListener('scroll', this.scrollFun);
          }
        });
      }
    },
    currScrollIndex(val) {
      this.$emit('emitAction', {
        action: 'setCurrScrollIndex',
        data: {
          currScrollIndex: val,
        },
      });
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {
    oldData = null;
    newData = null;
    if (scrollDom) {
      scrollDom.removeEventListener('scroll', this.scrollFun);
    }
    this.removeAllVideo();
  },
  methods: {
    playVideo(video) {
      const liveAddr = this.getLiveAddr(video);
      if (liveAddr) {
        this.$nextTick(() => {
          const dom = document.getElementById(`video-${video.siteID}`);
          const { siteID } = video;
          if (!dom || !FLV.isSupported()) return;
          flvPlayers[siteID] = FLV.createPlayer(
            {
              type: 'flv',
              hasAudio: false,
              isLive: true,
              // @ts-ignore
              liveBufferLatencyChasing: true,
              url: liveAddr,
            },
            {
              enableStashBuffer: false, // 是否启用缓存
              stashInitialSize: 8192, // 缓存大小(kb)  默认384kb
              autoCleanupSourceBuffer: true, // 是否自动清理缓存
            },
          );
          if (flvPlayers[siteID]) {
            flvPlayers[siteID].attachMediaElement(dom);
            flvPlayers[siteID].load();
            flvPlayers[siteID].play();
          }

          if (timer[siteID] !== null) {
            clearInterval(timer[siteID]);
          }
          timer[siteID] = setInterval(() => {
            if (dom.buffered.length > 0) {
              const end = flvPlayers[siteID].buffered.end(0); // 视频结尾时间
              const current = flvPlayers[siteID].currentTime; // 视频当前时间
              const diff = end - current; // 相差时间
              const diffCritical = 4; // 这里设定了超过4秒以上就进行跳转
              const diffSpeedUp = 1; // 这里设置了超过1秒以上则进行视频加速播放
              const maxPlaybackRate = 4; // 自定义设置允许的最大播放速度
              let playbackRate = 1.0; // 播放速度
              if (diff > diffCritical) {
                flvPlayers[siteID].currentTime = end - 1.5;
                playbackRate = Math.max(1, Math.min(diffCritical, 16));
              } else if (diff > diffSpeedUp) {
                playbackRate = Math.max(1, Math.min(diff, maxPlaybackRate, 16));
              }
              flvPlayers[siteID].playbackRate = playbackRate;
              if (flvPlayers[siteID].paused) flvPlayers[siteID].play();
            }
          }, 1000);
        });
      }
    },
    removePlay(siteID) {
      if (flvPlayers[siteID]) {
        flvPlayers[siteID].unload();
        flvPlayers[siteID].destroy();
        flvPlayers[siteID] = null;
      }
      this.removeTimer(siteID);
      // console.log(flvPlayers);
    },
    removeTimer(siteID) {
      if (timer[siteID]) {
        clearInterval(timer[siteID]);
        timer[siteID] = null;
      }
    },
    VideoEvent(data) {
      if (data && data.action === 'toggleVideoView') {
        this.toggleVideoView(data.data);
      }
      if (data && data.action === 'videoFullScreen') {
        this.videoFullScreen(data.data.videoId);
      }
      if (data && data.action === 'dragstart') {
        this.dragstart(data.data.value);
      }
      if (data && data.action === 'dragenter') {
        this.dragenter(data.data.value, data.data.e);
      }
      if (data && data.action === 'dragend') {
        this.dragend();
      }
      if (data && data.action === 'dragover') {
        this.dragover(data.data.e);
      }
    },
    removeAllVideo() {
      Object.keys(flvPlayers).forEach((siteID) => {
        // console.log('remove siteID', siteID);
        this.removePlay(siteID);
      });
    },
    toggleVideoView(video) {
      const { siteID } = video;
      this.removePlay(siteID);
      this.playVideo(video);
    },
    getLiveAddr(video) {
      const { first, second, internal, viewValue } = video;
      console.log(first, second, internal, viewValue );
      switch (viewValue) {
        case 'first':
          return first;
        case 'second':
          return second;
        case 'internal':
          return internal;
        default:
          return first;
      }
    },
    videoFullScreen(videoId) {
      this.$emit('emitAction', {
        action: 'videoFullScreen',
        data: {
          videoId,
        },
      });
    },
    dragstart(value) {
      oldData = value;
    },
    // 记录移动过程中信息
    dragenter(value, e) {
      newData = value;
      e.preventDefault();
    },
    // 拖拽最终操作
    dragend() {
      // 拖动空白窗口到空白窗口
      if (!oldData.siteID && !newData.siteID) {
        return;
      }
      if (oldData !== newData) {
        const oldIndex = this.videoList.indexOf(oldData);
        const newIndex = this.videoList.indexOf(newData);
        const newItems = [...this.videoList];
        // 互换位置
        newItems[newIndex] = this.videoList[oldIndex];
        newItems[oldIndex] = this.videoList[newIndex];
        this.videoList = [...newItems];
        const oldSiteID = oldData.siteID;
        const newSiteID = newData.siteID;
        // 拖动到空白的窗口
        if (!newData.siteID) {
          this.removePlay(oldSiteID);
          this.playVideo(oldData);
        } else {
          this.removePlay(oldSiteID);
          this.playVideo(oldData);
          this.removePlay(newSiteID);
          this.playVideo(newData);
        }
      }
    },
    // 拖动事件（主要是为了拖动时鼠标光标不变为禁止）
    dragover(e) {
      e.preventDefault();
    },
    scrollFun() {
      const currentY = scrollDom.scrollTop;
      if (currentY > this.cacheScrollY) {
        // console.log('down');
        this.isScrollUp = false;
      } else {
        // console.log('up');
        this.isScrollUp = true;
      }
      setTimeout(() => {
        this.cacheScrollY = currentY;
      }, 0);
      const domHei = scrollDom.offsetHeight; // 一屏的高度
      const bufferVal = 40;
      // console.log('domHei', domHei);
      // console.log('listHei', listHei);
      // console.log('canScrollNum', canScrollNum);
      // console.log('currentY', currentY);
      // console.log('this.currScrollIndex', this.currScrollIndex);
      if (this.isScrollUp) {
        // 往上滚
        if (currentY < this.currScrollIndex * domHei - bufferVal) {
          this.currScrollIndex -= 1;
          const scrollVal = domHei * this.currScrollIndex;
          scrollDom.scrollTop = scrollVal;
        }
        // 滚动到的区域等于0，scrollTop就等于0
        if (this.currScrollIndex === 0) {
          const scrollVal = 0;
          scrollDom.scrollTop = scrollVal;
        }
      } else if (currentY > this.currScrollIndex * domHei + bufferVal) {
        // 往下滚
        this.currScrollIndex += 1;
        const scrollVal = domHei * this.currScrollIndex;
        scrollDom.scrollTop = scrollVal;
      }
      // 滚动到的区域和列表可滚动的次数相等时，scrollTop就等于总高度 - 一屏的高度
      // if (this.currScrollIndex === canScrollNum) {
      //   const scrollVal = listHei - domHei;
      //   scrollDom.scrollTop = scrollVal;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.videos-box {
  margin: -4px;
  flex: 1;
  height: calc(100% + 8px);
  // background: #FFF;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  &.mode-4,
  &.mode-2 {
    .video-box {
      width: 50%;
      height: 50%;
    }
  }
  &.mode-6 {
    .video-box {
      width: 33.33%;
      height: 33.33%;
      &:first-child {
        width: 66.6%;
        height: 66.6%;
      }
    }
  }
  &.mode-9 {
    .video-box {
      width: 33.33%;
      height: 33.33%;
    }
  }
  &.mode-1 {
    .scroll-box {
      overflow-y: auto;
      height: 100%;
      .video-box {
        width: 50%;
        height: 50%;
      }
    }
  }
  .video-box {
    padding: 4px;
    float: left;
    transition: all 0.3s;
  }
}
</style>
