<template>
  <div class="x-point-title">
    {{ name }}
  </div>
</template>

<script>
export default {
  name: 'UiPointTitle',
  components: {},
  props: {
    name: {
      type: String,
      default: '',
    },
    fontStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.x-point-title {
  position: relative;
  font-size: 16px;
  padding-left: 20px;
  color: var(--color-primary) !important;
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #378cff;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    left: 0;
  }
  &.black-theme {
    color: var(--color-primary);
    &::before {
      background-color: #36c6fb;
    }
  }
}
</style>
