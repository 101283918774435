<template>
  <div class="block-mode">
    <div v-for="(item, index) in barList" :key="index" class="bar-item" @click="clickFun(item)">
      <div :class="['bar-icon iconfont', item.icon, { active: item.active }]" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockMode',
  components: {},
  props: {
    videoList: {
      type: Array,
      default: () => [],
    },
    boxNum: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      activeBoxNum: 4,
      barList: [
        {
          active: true,
          icon: 'icon-sigongge_ying',
          num: 4,
        },
        {
          active: false,
          icon: 'icon-buju',
          num: 6,
        },
        {
          active: false,
          icon: 'icon-jiugongge',
          num: 9,
        },
        {
          active: false,
          icon: 'icon-liebiao1',
          num: 1,
        },
      ],
    };
  },
  computed: {},

  watch: {},
  created() {},
  mounted() {},
  methods: {
    clickFun(data) {
      this.toggleBox(data);
    },
    toggleBox(data) {
      if (this.activeBoxNum === data.num) {
        return;
      }
      this.activeBoxNum = data.num || 4;
      this.barList.forEach((val) => {
        if (val.num === this.activeBoxNum) {
          Object.assign(val, {
            active: true,
          });
        } else {
          Object.assign(val, {
            active: false,
          });
        }
      });
      this.$emit('cut', {
        action: 'box',
        data: {
          num: this.activeBoxNum,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.block-mode {
  width: 60px;
  height: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  background-color: var(--main-el-card-header-background);

  .bar-item {
    position: relative;
    padding: 20px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--main-el-card-header-background);

    &:not(:last-child)::after {
      content: '';
      height: 1px;
      width: 26px;
      background: var(--main-border-color);
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -13px;
    }

    .bar-icon {
      font-size: 28px;
      color: var(--color-primary);
      margin: auto;

      &.active {
        color: var(--el-color-primary);
      }
    }
  }
}
</style>
