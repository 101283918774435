<template>
  <div class="list-main">
    <div class="page-header">
      <PointTitle name="站点列表" />
    </div>
    <div class="device-list x-scroll-white">
      <div v-for="item in siteList" :key="item.did" class="device-item" :class="{ active: item.active }" @click="toggleActive(item)">
        <div class="name eps-1">
          <span :name="item.siteName" class="search-color">{{ item.siteName }}</span>
        </div>
        <div class="id eps-1">
          <span>ID: {{ item.siteID }}</span>
        </div>
        <div class="time eps-1">
          <span>{{ $moment(item.createTime).format('YYYY-MM-DD HH:mm') }}</span>
        </div>
        <div class="device-pic">
          <img :src="item | siteImg" alt="img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PointTitle from './PointTitle.vue';
import { siteImage, uavImage } from '@/utils/DEVICE_IMAGE_ENUM.js';

const defaultImg = require('@/assets/images/device/default.png');

export default {
  name: 'OnlineList',
  components: {
    PointTitle,
  },
  filters: {
    siteImg(site) {
      let imgUrl = '';
      if (site.siteMode === 4 && site.UAVInfo) {
        imgUrl = uavImage(site.UAVInfo.model);
      } else if (site.siteMode === 1 && site.hiveinfo) {
        imgUrl = siteImage(site.hiveinfo.hiveModel);
      } else {
        imgUrl = defaultImg;
      }
      return imgUrl;
    },
  },
  props: {
    siteList: {
      type: Array,
      default: () => [],
    },
    videoList: {
      type: Array,
      default: () => [],
    },
    boxNum: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      cacheSiteId: {},
    };
  },
  methods: {
    toggleActive(item) {
      if (this.isVideoFull(item.active)) {
        return;
      }
      // this.searchText = '';
      // this.searchFun();
      if (item.active) {
        this.removeActiveList(item);
      } else {
        this.activeList(item);
      }
      // this.deviceList = this.deviceList.concat([]);
    },
    activeList(item) {
      this.$emit('emitAction', {
        action: 'addVideo',
        data: item,
      });
    },
    removeActiveList(item) {
      if (this.boxNum === 1) {
        return;
      }
      this.$emit('emitAction', {
        action: 'removeVideo',
        data: {
          siteID: item.siteID,
        },
      });
    },
    isVideoFull(active) {
      const activeList = this.siteList.filter((val) => val.active);
      if (activeList.length >= this.boxNum && !active) {
        this.$message.warning('视频列表已满，请先取消已播放的视频');
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.list-main {
  width: 230px;
  background-color: var(--main-el-card-header-background);

  .page-header {
    padding: 18px 20px;
    border-bottom: solid 1px rgba(195, 195, 195, 0.15);
  }
}

.no-list-data {
  margin-top: 98px;
  width: 100%;
  height: auto;
  text-align: center;

  img {
    width: 128px;
    height: 128px;
    margin-bottom: 23px;
  }

  p {
    height: 22px;
    font-size: 14px;
    color: var(--color-primary);
    line-height: 22px;
  }
}

.live-status {
  width: 14px;
  height: 14px;
  background: #1cd167;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: 6px;
  vertical-align: middle;
  margin-bottom: 4px;

  span {
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 4px transparent;
    border-left-color: #fff;
    position: absolute;
    top: 3px;
    left: 6px;
  }
}

.device-list {
  height: calc(100% - 61px);
  overflow-y: auto;

  .device-item {
    cursor: pointer;
    width: 100%;
    // height: 68px;
    padding: 12px 8px 4px;
    position: relative;
    margin-bottom: 10px;
    border-top: 1px solid var(--main-border-color);
    border-bottom: 1px solid var(--main-border-color);

    &.active {
      border-top: none;
      border-left: solid 2px var(--el-color-primary) !important;
      // background: #fafafa;
      background-color: var(--main-monitoring-site-item-fill);
    }

    &:hover {
      // background: #fafafa;
      background-color: var(--main-monitoring-site-item-fill);

      .btn-c {
        width: 80px;
      }
    }

    .name {
      width: 136px;
      // height: 20px;
      font-size: 14px;
      font-weight: bold;
      color: var(--el-color-primary);
      line-height: 20px;
    }

    .time {
      width: 150px;
      height: 14px;
      font-size: 12px;
      color: var(--color-primary);
      line-height: 14px;
      margin-bottom: 10px;
      margin-left: -3px;
      transform: scale(0.95);
    }

    .id {
      height: 17px;
      font-size: 12px;
      color: var(--color-primary);
      line-height: 17px;
      transform: scale(0.9);
      margin-left: -9px;
      margin-top: 4px;
    }

    .device-pic {
      position: absolute;
      top: 0;
      right: 6px;
      bottom: 0;
      width: 68px;
      overflow: hidden;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }
}
</style>
