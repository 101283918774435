const M210V2 = require('@/assets/images/device/uav_M210V2.png');
const M300 = require('@/assets/images/device/uav_M300.png');
const M30 = require('@/assets/images/device/uav_M30.png');
const MAVIC2 = require('@/assets/images/device/uav_MAVIC2.png');
const P4P = require('@/assets/images/device/uav_P4P.png');
const MAVIC2Pro = require('@/assets/images/device/uav_MAVIC2_Pro.png');
const DJI_AIR_2S = require('@/assets/images/device/uav_MAVIC2.png');

const UH_MK3 = require('@/assets/images/device/UH_MK3.png');
const UH_MK4 = require('@/assets/images/device/UH_MK4.png');

const UH_MK4_Pro = require('@/assets/images/device/UH_MK4_Pro.png');
const UH_MK5 = require('@/assets/images/device/UH_MK5.png');
const WK_YU_JK = require('@/assets/images/device/WK_YU_JK.png');
const MH_MK3 = require('@/assets/images/device/MH_MK3.png');

function uavImage(model) {
  const models = {
    '经纬 M210 V2': M210V2,
    '经纬 M300 RTK': M300,
    '经纬 M30': M30,
    '御 Mavic 2 行业版': MAVIC2,
    '精灵 Phantom 4 Pro': P4P,
    '御 2 行业进阶版': MAVIC2Pro,
    'DJI AIR 2S': DJI_AIR_2S,
  };
  return models[model] || '';
}

function siteImage(model) {
  const models = {
    UH_MK3,
    UH_MK4,
    UH_MK4_Pro,
    UH_MK5,
    WK_YU_JK,
    MH_MK3,
  };
  return models[model] || '';
}

export { uavImage, siteImage };
